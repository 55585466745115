import React, {useContext, useState} from 'react';
import ReactMarkdown from 'react-markdown'
import { Link, useStaticQuery, graphql } from "gatsby"
import Img, { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image"


//import {NewsHighlight} from '../components/newsHighlightsForPage1'
import {LanguageContext} from '../components/LanguageProvider'
import MainLayout from '../components/mainPageLayout'
import Date from '../components/dateReader'


export default function NewsOverview(){

    const dataFull  = useStaticQuery(graphql`
    query {
        allCmbPagesJson{
            edges{         
            node{
              genericItems{
                en{
                  seeMore
                  readMore
                }
                hr{
                  seeMore
                  readMore
                }
              }
              newsPage{
                        en{
                            header
                        }
                        hr {
                            header
                        }
                    }
                }
            }
        }
        allCmbNewsJson {
          edges {
            node {
              id
              date
              jsonId
              en {
                eventMain
              }
              hr {
                eventMain
              }
              mainImage {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }`)



let intro = dataFull.allCmbPagesJson.edges[0].node.newsPage




let titles = dataFull.allCmbPagesJson.edges[0].node.genericItems



    return (
        <LanguageContext.Provider value ={"hr"}>
            <MainLayout mainHeading = {intro}>
        <main className="newsPage">
        <NewsItem data = {dataFull.allCmbNewsJson.edges} titles = {titles}></NewsItem>
        </main>
    </MainLayout>
    </LanguageContext.Provider>
    )
}




function NewsItem({data, titles}) {
    let context = useContext(LanguageContext)
     let [nItems, setNItems] = useState(3)


     console.log(data)
     //const data = require('../data/cmbNews')
    let subSet = data.slice(0,nItems)
    let totalItems = data.length
    let x = subSet.map((item, index)=><NewsHighlight count = {0} data ={item} titles ={titles}/>)
    let viewMore =()=>{
        let newN;
        nItems+1>totalItems ? newN = totalItems : newN = nItems + 1;
         setNItems(newN)
}

let moreButton;
nItems !==totalItems ? moreButton =  <button className="moreButton" onClick={viewMore}>{titles[context].seeMore}</button> : moreButton="";
    return (<>
    {x}
    {moreButton}
    </>
    )
}


/*
function IndividualNews({data}){

    let image = getImage(data.node.mainImage)
    let context = useContext(LanguageContext)
return(
<article>
        <section className="newsText">
            <Date date={data.node.date} ></Date>
           <ReactMarkdown components={{h1:"h2"}}>{data.node[context].eventMain}</ReactMarkdown>
           <Link to={`/news/${data.node.jsonId}`} className={"newsLink"}>{"Read More..."}</Link>
           
            
        </section>
            <GatsbyImage className = "newsImage" image={image} alt=" " />

    </article>
)
}

*/


export function NewsHighlight(props) {
  const context = useContext(LanguageContext)
  let count = props.count
  const currentNews = props.data
  console.log(currentNews)
      let image = getImage(currentNews.node.mainImage);
 let x = (
      <div className={"newsHighlight"}>
          <GatsbyImage className={"newsHighImg"} image={image} alt="" />
          <Date date={currentNews.node.date}></Date>
          <ReactMarkdown includeElementIndex components={{ h1: "h3", p: (node, index) => clipped(node) }} allowElement={(node, index) => [0, 2].indexOf(index) !== -1 ? true : false}>{currentNews.node[context].eventMain}</ReactMarkdown>
          <Link to={`/news/${currentNews.node.jsonId}`}>{props.titles[context].readMore}</Link>
      </div>
  )
  return x
}



export function clipped(props) {
  let text = props.children[0]
  let x = text.slice(0, 100) + "..."
  return <p>{x}</p>
}

